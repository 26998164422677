<template>
  <div class="credit">
    <div class="title">建设银行农业生产统一经营信贷条款</div>
    <div class="content">
      <div class="line">
        <div class="label">贷款背景</div>
        <div class="detail">
          与省农业担保融资有限公司、淮南农业农村局、保险公司合作开发的统一生产经营专属信贷服务。
        </div>
      </div>
      <div class="line">
        <div class="label">贷款对象</div>
        <div class="detail">村集体经济组织</div>
      </div>
      <div class="line">
        <div class="label">贷款用途</div>
        <div class="detail">
          一是支付土地托管费用；<br />
          二是生产成本资金投入;<br />
          三是基础设施建设；<br />
          四是粮食收购加工费用。
        </div>
      </div>
      <div class="line">
        <div class="label">贷款额度</div>
        <div class="detail">
          支付托管费用按（400-1000元）/亩测算；生产成本投入按（400-600元）/亩
          测算；基础设施建设、粮食加工费用按实际
          投入测算，单户贷款额度不超过500万元，根据实际情况最高可放大至1000万元。
        </div>
      </div>
      <div class="line">
        <div class="label">贷款期限</div>
        <div class="detail">一般为1年期，贷款期限最长不超过3年。</div>
      </div>
      <div class="line">
        <div class="label">贷款定价</div>
        <div class="detail">参考贷款市场报价利率（LPR），低至3.85%起。</div>
      </div>
      <div class="line">
        <div class="label">计息方式</div>
        <div class="detail">按日计息，按月或按季结息。</div>
      </div>
      <div class="line">
        <div class="label">还款方式</div>
        <div class="detail">
          一年期的贷款到期一次性偿还本金；一年期以上的贷款采用分期还款方式 偿还本金。
        </div>
      </div>
      <div class="line">
        <div class="label">贷款材料</div>
        <div class="detail strong">
          1、登记证书；<br />
          2、法人代表身份证复印件；<br />
          3、农村集体经济组织章程（包含完整的成员名册，记载姓名、身份证、
          电话号码等）；<br />
          4、理事会借款决议；<br />
          5、理事会成员名单及身份证复印件；<br />
          6、土地托管总面积（提供汇总表及部分托管协议）；<br />
          7、上一年末及近期财务报表；<br />
          8、经营性固定资产明细及价值表；<br />
          9、配套农业设施明细及价值。<br />
        </div>
      </div>
      <a-button type="primary" @click="showModel"> 贷款申请 </a-button>
    </div>
    <a-modal v-model="visible" title="贷款申请" :footer="null">
      <a-form-model ref="ngjFinanceLoanForm" :model="ngjFinanceLoan" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" >
        <a-form-model-item label="客户姓名" prop="customName">
          <a-input v-model="ngjFinanceLoan.customName" />
        </a-form-model-item>
        <a-form-model-item label="客户手机" prop="customPhone">
          <a-input v-model="ngjFinanceLoan.customPhone" />
        </a-form-model-item>
        <a-form-model-item label="客户地址" prop="location">
          <a-input v-model="ngjFinanceLoan.location" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="handleOk">
            提交
          </a-button>
          <a-button style="margin-left: 10px;" @click="handleCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script type="text/ecmascript-6">
import Api from "@/api/trusteeships";
export default {
  data() {
    return {
      visible:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      ngjFinanceLoan: {
        customName: '',
        customPhone: '',
        location: '',
      },
      rules: {
        customName: [
          { required: true, message: 'Please input customName', trigger: 'blur' },
        ],
        customPhone: [
          { required: true, message: 'Please input customPhone', trigger: 'blur' },
        ],
        location: [
          { required: true, message: 'Please input location', trigger: 'blur' },
        ],
        
      },
    }
  },
  components: {

  },
  methods: {
    showModel(){
      this.visible = true;
      this.ngjFinanceLoan={};
    },
    handleOk() {
      this.$refs.ngjFinanceLoanForm.validate(valid => {
         if (valid) {
          Api.addLoan({...this.ngjFinanceLoan })
        .then((res) => {
          console.log(res.code);
          this.visible = false;
        })
        .catch(() => (this.loading = false));
        } else {
          return false;
        }
      });
      
    },
    handleCancel() {
      this.visible = false;
    },
  },
}
</script>

<style scoped lang="scss">
.title {
  color: #008840;
  font-size: 18px;
  text-indent: 1em;
  background: #f9f9f9;
  border-bottom: 1px solid #008840;
  margin-bottom: 15px;
  line-height: 59px;
  font-weight: bold;
}
.content {
  margin-bottom: 30px;
  .line {
    overflow: hidden;
    margin-bottom: 10px;
    .label {
      float: left;
      background: url("~@/assets/images/finance_insurance_label.png") no-repeat center
        center / 100%;
      font-size: 16px;
      color: white;
      padding: 7px 10px;
    }
    .detail {
      float: left;
      width: 80%;
      line-height: 2em;
      padding-top: 0.6em;
      margin-left: 20px;
      &.strong {
        color: #68cba5;
      }
    }
  }
}
</style>
