import request from '@/utils/request';

export default {
    list(params = {}) {
        return request.get("/vcngj/list", {
            params
        });
    },
    detail(contractId) {
        return request.get(`/vcngj/${contractId}`)
    },
    addLoan(params = {}) {
        return request.post("/loan/add", 
            params
        );
    },
    addInsure(params = {}) {
        return request.post("/insure/add", 
            params
        );
    },
}